/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Girish's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Girish Budhrani Portfolio",
    type: "website",
    url: "http://girishamarbudhrani.com/",
  },
};

//Home Page
const greeting = {
  title: "Girish Budhrani",
  logo_name: "Girish Budhrani",
  nickname: "MRGAB",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  // resumeLink:
  //   "https://drive.google.com/file/d/1kPjmwb0r6rDb-b3UHE1ZtJ3eL18j5y8S/view",
  // portfolio_repository: "https://github.com/Girish1919/GirishBudhrani",
  githubProfile: "https://github.com/GABudhrani",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/GABudhrani",
  // linkedin: "https://www.linkedin.com/in/Girish-Budhrani-88710b138/",
  // gmail: "GirishBudhrani98@gmail.com",
  // gitlab: "https://gitlab.com/GirishBudhrani98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/Girish_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/GABudhrani",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/gb06/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://youtube.com/c/DevSense19",
  //   fontAwesomeIcon: "icon-park:phone-call", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "email",
    link: "mailto:girishamarbudhrani@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Phone",
    link: "tel:+918888000408",
    fontAwesomeIcon: "fa fa-phone", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#098436", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "Twitter",
  //   link: "https://twitter.com/Girish_1919",
  //   fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
  //   backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  // },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/laymanbrother.19/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/layman_brother/",
  //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },

];

const skills = {
  data: [
    {
      title: "Machine Learning Engineer & Data Scientist",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
        "⚡ Designing and implementing machine learning models for NLP/LLM projects, Computer Vision projects, Deep Learning models for classification, regression, predictive analysis, clustering, classification etc.",
        "⚡ Working with big datasets to extract insights and knowledge",
        "⚡ Developing algorithms from scratch in Python or using libraries like TensorFlow, PyTorch etc.",
        "⚡ Implementing ML models on cloud platforms like AWS, Google Cloud and also creating dashboards using tools like Tableau or Power BI", 
        "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "vscode-icons:file-type-python",
          style: {
            backgroundColor: "transparent",
            // color: "#3776AB",
          },
        },
        {
          skillName: "R language",
          fontAwesomeClassname: "carbon:logo-r-script",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end React-Redux", 
        "⚡ Developing mobile applications using Flutter and solo android apps using Java", 
        "⚡ Creating serverless backends in AWS cloud with Node, Express & MongoDB",
        "⚡ Deploying the developed application to Heroku, Netlify and many others for web hosting", 
        "⚡ Creating application backend in Node, Express & Flask", 
        "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean for storing data.", 
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        // {
        //   skillName: "Sass",
        //   fontAwesomeClassname: "simple-icons:sass",
        //   style: {
        //     color: "#CC6699",
        //   },
        // },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        // {
        //   skillName: "Yarn",
        //   fontAwesomeClassname: "simple-icons:yarn",
        //   style: {
        //     color: "#2C8EBB",
        //   },
        // },
        {
          skillName: "Android Studio",
          fontAwesomeClassname: "mdi:android-studio",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms", 
        "⚡ Hosting and maintaining websites, applications, and backends on a scale",
        "⚡ Knowledge of AWS (EC2, S3, CloudFront, Lambda), Google Cloud Platform (GCP)", 
        "⚡ Understanding of containerization technologies like Docker", 
        "⚡ Hosting and maintaining applications on virtual machine instances along with integration of databases", 
        "⚡ Familiarity with serverless computing platforms such as AWS Lambda and Google Cloud Functions", 
        "⚡ Deploying deep learning models on cloud to use on multiple platforms", 
        "⚡ Setting up streaming jobs from DB to Server or vice-versa AWS", 
      ],
      softwareSkills: [
        // {
        //   skillName: "GCP",
        //   fontAwesomeClassname: "simple-icons:googlecloud",
        //   style: {
        //     color: "#4285F4",
        //   },
        // },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        // {
        //   skillName: "Azure",
        //   fontAwesomeClassname: "simple-icons:microsoftazure",
        //   style: {
        //     color: "#0089D6",
        //   },
        // },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        // {
        //   skillName: "Docker",
        //   fontAwesomeClassname: "simple-icons:docker",
        //   style: {
        //     color: "#1488C6",
        //   },
        // },
        // {
        //   skillName: "Kubernetes",
        //   fontAwesomeClassname: "simple-icons:kubernetes",
        //   style: {
        //     color: "#326CE5",
        //   },
        // },
      ],
    },
    // {
    //   title: "UI/UX Design",
    //   fileName: "DesignImg",
    //   skills: [
    //     "⚡ Designing highly attractive user interface for mobile and web applications",
    //     "⚡ Customizing logo designs and building logos from scratch",
    //     "⚡ Creating the flow of application functionalities to optimize user experience",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Adobe XD",
    //       fontAwesomeClassname: "simple-icons:adobexd",
    //       style: {
    //         color: "#FF2BC2",
    //       },
    //     },
    //     {
    //       skillName: "Figma",
    //       fontAwesomeClassname: "simple-icons:figma",
    //       style: {
    //         color: "#F24E1E",
    //       },
    //     },
    //     {
    //       skillName: "Adobe Illustrator",
    //       fontAwesomeClassname: "simple-icons:adobeillustrator",
    //       style: {
    //         color: "#FF7C00",
    //       },
    //     },
    //     {
    //       skillName: "Inkscape",
    //       fontAwesomeClassname: "simple-icons:inkscape",
    //       style: {
    //         color: "#000000",
    //       },
    //     },
    //   ],
    // },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/gbudhrani/",
    },
    // {
    //   siteName: "HackerRank",
    //   iconifyClassname: "simple-icons:hackerrank",
    //   style: {
    //     color: "#2EC866",
    //   },
    //   profileLink: "https://www.hackerrank.com/layman_brother",
    // },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/users/Girish_1919",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/layman_brother",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@Girish391",
    // },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/girishbudhrani",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Stevens Institute of Technology",
      subtitle: "Machine Learning Certification",
      logo_path: "stevens-institute-of-technology-logo.png",
      alt_name: "SIT logo",
      duration: "May 2023",
      descriptions: [
        "⚡ Completed a certification program in Machine Learning, covering key concepts, algorithms, and applications in field",
      ],
      website_link: "https://www.stevens.edu/",
    },
    {
      title: "Stevens Institute of Technology",
      subtitle: "M.S. in Computer Science",
      logo_path: "stevens-institute-of-technology-logo.png",
      alt_name: "SIT logo",
      duration: "August 2021 - May 2023",
      descriptions: [
        "⚡ I have taken varity of courses related to Artificial Intelligence which correspond to Explainable AI, Machine Learning, Deep learning, Computer Vision etc.",
        "⚡ Developed a web application using NodeJS, Express and MongoDB to create and join the video meetings.",
        "⚡ Apart from this, I have also done volunteer research assistantship. As part of it, I have worked on many NLP projects",
        "⚡ Built an automated system that uses Natural Language Processing (NLP) to generate reports from large datasets.", 
        "⚡ Built an automated system that uses Natural Language Processing (NLP) to analyze emails and classifies them into spam or non-spam categories.", 
        "⚡ I also participated in a WMT Competition and was awarded first prize.",
        "⚡ I also had one paper published on NLP. (https://arxiv.org/pdf/2210.11670.pdf)",
      ],
      website_link: "https://www.stevens.edu/",
    },
    {
      title: "Kishinchand Chellaram College",
      subtitle: "B.S.C in Information Technology",
      logo_path: "KCC_Mumbai_logo.svg",
      alt_name: "KC Logo",
      duration: "July 2017 - October 2020",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, and more.",
        "⚡ Apart from this, I have Published one National conference paper on 'Influence of Information Technology on society'",
      ],
      website_link: "https://kccollege.edu.in/",
    },
    
  ],
};

// const certifications = {
//   certifications: [
//     {
//       title: "Machine Learning",
//       subtitle: "- Andrew Ng",
//       logo_path: "stanford_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
//       alt_name: "Stanford University",
//       color_code: "#8C151599",
//     },
//     {
//       title: "Deep Learning",
//       subtitle: "- Andrew Ng",
//       logo_path: "deeplearning_ai_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
//       alt_name: "deeplearning.ai",
//       color_code: "#00000099",
//     },
//     {
//       title: "ML on GCP",
//       subtitle: "- GCP Training",
//       logo_path: "google_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
//       alt_name: "Google",
//       color_code: "#0C9D5899",
//     },
//     {
//       title: "Data Science",
//       subtitle: "- Alex Aklson",
//       logo_path: "ibm_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
//       alt_name: "IBM",
//       color_code: "#1F70C199",
//     },
//     {
//       title: "Big Data",
//       subtitle: "- Kim Akers",
//       logo_path: "microsoft_logo.png",
//       certificate_link:
//         "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
//       alt_name: "Microsoft",
//       color_code: "#D83B0199",
//     },
//     {
//       title: "Advanced Data Science",
//       subtitle: "- Romeo Kienzler",
//       logo_path: "ibm_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
//       alt_name: "IBM",
//       color_code: "#1F70C199",
//     },
//     {
//       title: "Advanced ML on GCP",
//       subtitle: "- GCP Training",
//       logo_path: "google_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
//       alt_name: "Google",
//       color_code: "#0C9D5899",
//     },
//     {
//       title: "DL on Tensorflow",
//       subtitle: "- Laurence Moroney",
//       logo_path: "deeplearning_ai_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
//       alt_name: "deeplearning.ai",
//       color_code: "#00000099",
//     },
//     {
//       title: "Fullstack Development",
//       subtitle: "- Jogesh Muppala",
//       logo_path: "coursera_logo.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
//       alt_name: "Coursera",
//       color_code: "#2A73CC",
//     },
//     {
//       title: "Kuberenetes on GCP",
//       subtitle: "- Qwiklabs",
//       logo_path: "gcp_logo.png",
//       certificate_link:
//         "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
//       alt_name: "GCP",
//       color_code: "#4285F499",
//     },
//     {
//       title: "Cryptography",
//       subtitle: "- Saurabh Mukhopadhyay",
//       logo_path: "nptel_logo.png",
//       certificate_link:
//         "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
//       alt_name: "NPTEL",
//       color_code: "#FFBB0099",
//     },
//     {
//       title: "Cloud Architecture",
//       subtitle: "- Qwiklabs",
//       logo_path: "gcp_logo.png",
//       certificate_link:
//         "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
//       alt_name: "GCP",
//       color_code: "#4285F499",
//     },
//   ],
// };

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies as Machine Learning Engineer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Machine Learning Engineer",
          company: "Marketing Arcc",
          company_url: "#",
          logo_path: "marketing-arc.png",
          duration: "June 2023 - Current",
          location: "Mumbai, India",
          description:["Implementing Agile development methodology within the cross-functional team and acting as a liaison between the business user group and the technical team. ", 
          "Developing and deploying a machine learning model using Python that improve customer churn prediction accuracy by 25%, resulting in a 10% reduction in customer attrition and increased revenue.", 
          "Designing and implementing a scalable SQL database architecture, allowing for efficient storage and retrieval of machine learning datasets, leading to a 50% reduction in data access latency.", 
          "Responsible for developing machine learning models to predict the risk of customer default on insurance policies using various data sources such as claims database, policyholder information, demographic", 
          "Developed a model to predict the risk of claims using various features like demographic data, medical history etc.", 
          "Built a predictive model for fraud detection in the insurance industry using Python.", 
          "Designed an automated dashboard to visualize data using Tableau.",
          "Use pandas, NumPy, seaborn, SciPy, Matplotlib, and Scikit-learn in Python for developing various machine learning algorithms.", 
          "Implementing a logistic regression model in Python, achieving a 30% increase in customer churn prediction accuracy, enabling targeted retention strategies, and reducing customer attrition. Integrating machine learning models with Tableau visualizations, enabling real-time predictions and achieving a 30% increase in user engagement and adoption of data-driven insights."
        ],
            
          color: "#0879bf",
        },
        {
          title: "Deep Learning Developer",
          company: "Marketing Arcc",
          company_url: "https://marketingarcc.com/",
          logo_path: "marketing-arc.png",
          duration: "October 2020 - August 2021",
          location: "Mumbai, India",
          description:[
            "Worked in Waterfall Methodology and was involved in the project discussions.",
            "Conducted feature engineering and implemented a random forest model in Python, resulting in a 20% increase in customer purchase prediction accuracy and improved targeted marketing campaigns.",
            "Developed and optimized SQL queries to aggregate and analyze time-series data, resulting in a 30% reduction in data processing time and improved forecasting accuracy for demand planning.", 
            "Developed a web application using Django Framework which provided various functionalities like user authentication.",
            "Developed a Natural Language Processing (NLP) based chatbot which reduced customer queries by 30%.",
            "Developed machine learning models from scratch with no supervision or pre-trained algorithms.", 
            "Successfully built a recommendation system for product suggestions on ecommerce websites.", 
            "Developed and implemented a machine learning algorithm that reduced false positive rates by 40% in detecting fraudulent transactions, resulting in significant cost savings for the company.",
            "Developed a time-series regression model using Python, achieving a 30% improvement in forecasting accuracy for stock prices, enabling more informed investment decisions.",
            "Implemented cost optimization strategies on AWS, reducing monthly infrastructure costs by 35% while maintaining high performance machine learning workflows.", 
            "Conducted data preprocessing and cleansing using MySQL, reducing data anomalies and outliers by 30% and improving data quality for machine learning model training.",
          ],
          color: "#D83B01",
        },
      ],
    },
    
    {
      title: "NLP Research Assistantship",
      experiences: [
        {
          title: "Research Assistantship(under professor Jia Xu)",
          company: "Stevens Institute of Technology",
          company_url: "https://www.stevens.edu/",
          logo_path: "stevens-institute-of-technology-logo.png",
          // duration: "June 2022 - December 2022",
          location: "Hoboken, NJ",
          description:[
            "Worked on Topics like:- ",
            "GLUE Benchmark Platform (Embargoed)",
            "WMT Machine translation:- Conducted models such as NLLB - 200, MT5, T5, M2M100, fairseq and Mbart-50 on NLP task performs machine translation from HG-HI (Hinglish – Hindi). Also created synthetic dataset for Hinglish by reversing model HG – HI and passing Hindi sentences to model",
          ],
          color: "#4285F4",
        },
        {
          title: "Research Assistantship(under professor Susan Liu)",
          company: "Stevens Institute of Technology",
          company_url: "https://www.stevens.edu/",
          logo_path: "stevens-institute-of-technology-logo.png",
          // duration: "Janurary 2023",
          location: "Hoboken, NJ",
          description:[
            "Worked on Hate Moderate (Embargoed)", 
            "FLAML open-source library:- Merged two platform FLAML library (FLAML) and Machine Translation (Machine Translation) on dataset WMT- 16 news. Lead team to fine-tune a machine translation on language pair German to English (DE-EN) using T5 tiny model provided by hugging face. Executed T5 tiny model where FLAML automatically fine-tune and return best parameters. Faced challenges to locate every code and construct a task in FLAML files",
          ],
          color: "#D83B01",
        },
        // {
        //   title: "Artificial Intelligence's Course Assistant",
        //   company: "Stevens Institute of Technology",
        //   company_url: "https://www.stevens.edu/",
        //   logo_path: "stevens-institute-of-technology-logo.png",
        //   duration: "Oct 2019 - May 2020",
        //   location: "Kurnool, Andhra Pradesh",
        //   description:
        //     "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
        //   color: "#000000",
        // },
        // {
        //   title: "Artificial Intelligence's Course Assistant",
        //   company: "Stevens Institute of Technology",
        //   company_url: "https://www.stevens.edu/",
        //   logo_path: "stevens-institute-of-technology-logo.png",
        //   duration: "Jan 2018 - May 2020",
        //   location: "Kurnool, Andhra Pradesh",
        //   description:
        //     "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
        //   color: "#0C9D58",
        // },
        // {
        //   title: "Artificial Intelligence's Course Assistant",
        //   company: "Stevens Institute of Technology",
        //   company_url: "https://www.stevens.edu/",
        //   logo_path: "stevens-institute-of-technology-logo.png",
        //   duration: "July 2019 - PRESENT",
        //   location: "Work From Home",
        //   description:
        //     "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
        //   color: "#181717",
        // },
      ],
    },
    // {
    //   title: "Internship",
    //   experiences: [
    //     {
    //       title: "Machine Learning Engineer",
    //       company: "GN Solutions INC",
    //       // company_url: "https://www.stevens.edu/",
    //       logo_path: "GN.jpg",
    //       duration: "April 2020 - August 2020",
    //       location: "Maharashtra, India",
    //       description:
    //         "During my internship as a Machine Learning Engineer at GN Solutions in India (April 2020 - August 2020), I successfully developed and implemented CNN models for image classification tasks, achieving an accuracy rate of 85% and improving the company's product recommendation system by 12%. Additionally, I conducted data preprocessing, including image augmentation and normalization, to enhance model performance and generalization. Working collaboratively with cross-functional teams, I gathered requirements, developed proof-of-concepts, and deployed models in a production environment. Throughout this experience, I utilized TensorFlow and Keras to build deep learning models and optimized hyperparameters to achieve improved performance.",
    //       color: "#0879bf",
    //     },
    //   ],
    // },
     {
      title: "Awards",
      experiences: [
        {
          title: "SIT at MixMT 2022: Fluent Translation Built on Giant Pre-trained ModelsMachine Learning Engineer",
          company: "Stevens Institute of Technology",
          company_url: "https://www.stevens.edu/",
          logo_path: "stevens-institute-of-technology-logo.png",
          duration: "August 2022",
          location: "NJ, USA",
          description:
            "The task consisted of two subtasks, subtask 1 Hindi/English to Hinglish and subtask 2 Hinglish to English translation. Our findings lie in the improvements made through the use of large pre-trained multilingual NMT models and in-domain datasets, as well as back-translation and ensemble techniques. The translation output is automatically evaluated against the reference translations using ROUGE-L and WER. Our system achieves the 1st position on subtask 2 according to ROUGE-L, WER, and human evaluation, 1st position on subtask 1 according to WER and human evaluation, and 3rd position on subtask 1 with respect to ROUGE-L metric.",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Publications",
      experiences: [
        {
          title: "SIT at MixMT 2022: Fluent Translation Built on Giant Pre-trained Models, Stevens Institute of Technology",
          company: "Paper link",
          company_url: "https://arxiv.org/abs/2210.11670",
          logo_path: "stevens-institute-of-technology-logo.png",
          duration: "October 2022",
          location: "NJ, USA",
          description:
            "This paper outlines the participation of Stevens Institute of Technology in the WMT 2022 Shared Task: Code-mixed Machine Translation (MixMT). The task involved translating between Hindi/English and Hinglish, with a focus on leveraging large pre-trained multilingual NMT models, in-domain datasets, and techniques like back-translation and ensemble methods. Evaluation against reference translations using ROUGE-L and WER shows that the system secured the 1st position on subtask 2 across various metrics and human evaluation. For subtask 1, it achieved the 1st position based on WER and human evaluation, and the 3rd position based on the ROUGE-L metric.",
          color: "#0879bf",
        },
        {
          title: "Testing Hateful Speeches against Policies, Stevens Institute of Technology",
          company: "Paper link",
          company_url: "https://arxiv.org/abs/2307.12418",
          logo_path: "stevens-institute-of-technology-logo.png",
          duration: "July 2023",
          location: "NJ, USA",
          description:
            "The paper explores how AI, particularly deep learning, aligns with rule-based requirements in natural language policies. Using a case study on content moderation, the study collects test cases through crowdsourcing (HateModerate dataset) and tests state-of-the-art hate speech detection software. High failure rates are identified for certain policies. To address manual labeling costs, the study proposes an automated approach, fine-tuning OpenAI's large language models to augment HateModerate by automatically matching new examples to policies.",
          color: "#D83B01",
        },
      ],
    },
    
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using Heoku.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
      name: "Testing Hateful Speeches against Policies, Stevens Institute of Technology",
      createdAt: "2023-07-10T16:26:54Z",
      description: "The paper explores how AI, particularly deep learning, aligns with rule-based requirements in natural language policies. Using a case study on content moderation, the study collects test cases through crowdsourcing (HateModerate dataset) and tests state-of-the-art hate speech detection software. High failure rates are identified for certain policies. To address manual labeling costs, the study proposes an automated approach, fine-tuning OpenAI's large language models to augment HateModerate by automatically matching new examples to policies.",
      url:
        "https://arxiv.org/abs/2307.12418",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Fluent Translation Built on Giant Pre-trained Models",
      createdAt: "2022-10-21T16:26:54Z",
      description: "Code-mixed Machine Translation (MixMT). The task consisted of two subtasks, subtask 1 Hindi/English to Hinglish and subtask 2 Hinglish to English translation. ",
      url:
        "https://arxiv.org/abs/2210.11670",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Smart Ordering Application using Java",
      createdAt: "2020-06-10T16:26:54Z",
      description: "The task was to place an order in a restaurant using of the Tab instead of the old method(to call waiter and order food through him/her)",
      url:
        "#",
    },
    
    // {
    //   id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
    //   name: "Artificial Intelligence Paper",
    //   createdAt: "2020-03-06T16:26:54Z",
    //   description: "Paper Written on Artificial Intelligence published in xyz ",
    //   url:
    //     "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    // },
    // {
    //   id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
    //   name: "Artificial Intelligence Paper",
    //   createdAt: "2020-03-06T16:26:54Z",
    //   description: "Paper Written on Artificial Intelligence published in xyz ",
    //   url:
    //     "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    // },
    // {
    //   id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
    //   name: "Artificial Intelligence Paper",
    //   createdAt: "2020-03-06T16:26:54Z",
    //   description: "Paper Written on Artificial Intelligence published in xyz ",
    //   url:
    //     "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    // },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_Girish.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, and Cloud",
  },
  // blogSection: {
  //   title: "Blogs",
  //   subtitle:
  //     "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
  //   link: "https://blogs.GirishBudhrani.com/",
  //   avatar_image_path: "blogs_image.svg",
  // },
  addressSection: {
    title: "Address",
    subtitle:
      "Kalyan, Maharashtra, India",
    avatar_image_path: "address_image.svg",
    // location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  // certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
